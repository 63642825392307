import keymirror from 'keymirror'

export const NOTIFICATION_CLOSE_TIME = 5000

export enum FEATURES {
  WORKFLOW_GEOFENCE_ANCILLARY_DATA = 'workflowGeofenceAncillaryData',
  WORKFLOW = 'rulesEngine',
  TILE_LAYER = 'tileLayer',
  ANCILLARY_DATA = 'assetsManagement',
  ISSUE = 'issue',
  SITE = 'site',
  ASSET = 'asset',
  MAPS = 'maps',
  DYNAMIC_SCHEDULING = 'dynamicScheduling',
  CREATE_USER = 'createUser',
  JSON_FORM = 'jsonForm',
  JSON_FORM_LIST_BUILDER = 'jsonFormListBuilder',
  LDAR_SIM_FILE_UPLOADER = 'ldarSimFileUploader',
  DISPLAY_VENTING_EVENT_LOCATION_ON_MAP_IN_GEMS = 'displayVentingEventLocationOnMapInGems',
  DISPLAY_QUBE_ALARM_LOCATION_ON_MAP_IN_GEMS = 'displayQubeAlarmLocationOnMapInGems',
  ENABLE_GROSS_EMISSIONS_DASHBOARD = 'enableGrossEmissionsDashboard',
  GEMS_CONFIGURATION = 'gemsConfiguration',
  ALLOW_DONE_ACTION_FOR_DAC_SITES_FLYOVER_IN_GEMS = 'allowDoneActionForDacSitesFlyoverInGems',
  SERP_REPORTING = 'serfReporting',
  AIR_MONITOR_RESPONSE = 'airMonitorResponse',
  ALLOW_REPROCESS_RECONCILIATION_FOR_A_CLOSED_MONTH = 'allowReprocessReconciliationForAClosedMonth',
  CREATE_AN_ISSUE_FROM_AN_ACTION_ON_A_DETECTION_IN_AN_INBOX = 'createAnIssueFromAnActionOnADetectionInAnInbox',
  ALLOW_RECONCILIATION_REOPENING_FOR_THE_SITE = 'allowReconciliationReopeningForTheSite',
  OPS_COMMENT_SETTINGS = 'opsCommentSettings',
  OGI_REQUEST_SETTINGS = 'ogiRequestSettings',
  OGI_DATA_COLLECTION_APP_ID = 'ogiDataCollectionAppId',
  DRONE_REQUEST_SETTINGS = 'droneRequestSettings',
  CAUSE_ANALYSIS = 'causeAnalysisAction',
  REQUEST_VFB_REPORT = 'requestVfbReportAction',

  // DASHBOARDS
  BRIDGER_EXEC_DASHBOARD = 'bridgerExecDashboard',
  STUDIO_DASHBOARDS = 'studioDashboards',
  FUGITIVES_DASHBOARD_SETTINGS = 'resolveFugitivesDashboardSettings',

  // SCADA
  SCADA_CLUE_DETECTION_BLADE = 'scadaClueDetectionBlade',
  SCADA_CLUE_EMISSION_EVENT_BLADE = 'scadaClueEmissionEventBlade',

  // ASSETS
  ASSETS_PAGE_GROUP_BY_OPTION = 'assetsPageGroupByOption',

  // Emission Events
  EMISSION_EVENTS_SHOW_CLUES = 'emissionEventsShowClues',
  EMISSION_EVENTS_STATISTICS = 'emissionEventsStatistics',
  EMISSION_EVENTS_ATTRIBUTION = 'emissionEventsAttribution',
  EMISSION_EVENTS_CORRELATION = 'emissionEventsCorrelation',
  EMISSION_EVENTS_ACTIONS = 'emissionEventsActions',
  EMISSION_EVENTS_ISSUE_REQUESTS = 'emissionEventsIssueRequests',

  // Emission Events by asset
  EMISSION_EVENTS_ASSET_SUMMARIES = 'emissionEventsAssetSummaries',
  EMISSION_EVENTS_ASSET_TRENDS = 'emissionEventsAssetTrends',

  OGMP2_YEARLY_RECONCILIATION = 'ogmp2YearlyReconciliation',
  OGMP2_MONTHLY_RECONCILIATION = 'ogmp2MonthlyReconciliation',
  OGMP2 = 'ogmp2',
  EMISSION_EVENTS = 'emissionEvents',
  RECONCILIATION = 'reconciliation',
  DASHBOARDS = 'dashboards',

  // Inbox and WIP
  INBOX = 'inbox',
  ASSET_FILTERS_FOR_INBOX_AND_WIP = 'assetFiltersForInboxAndWip',

  // Login redirect
  LOGIN_REDIRECT_URL = 'loginRedirectUrl',
  LOGIN_REDIRECT_METHANE = 'loginRedirectMethane',
}

export const MODAL_SIZES = keymirror({
  extraSmall: null,
  small: null,
  regular: null,
  large: null,
})

export const MODAL_TYPES = keymirror({
  warning: null,
  info: null,
  danger: null,
})

export const SETTINGS_TYPES = keymirror({
  userProfile: null,
  securityAndPrivacy: null,
  versions: null,
  formDesigner: null,
})

export const TOOLTIP_PLACEMENT = keymirror({
  left: null,
  right: null,
  top: null,
  bottom: null,
  topLeft: null,
  topRight: null,
  bottomLeft: null,
  bottomRight: null,
  rightTop: null,
  rightBottom: null,
  leftTop: null,
  leftBottom: null,
})

export const USER_PREFERENCES = keymirror({
  sidebarRoutes: null,
  filters: null,
  favoriteMaps: null,
  favoriteFormLists: null,
  enableTimezone: null,
  galleryOptions: null,
})
